@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(assets/fonts/Poppins-Medium.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins" !important;
}

.MuiTypography-root{
  font-family: "Poppins" !important;
}