input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.hover-blue:hover{
    background-color: #999999;
}
.rec.rec-arrow:hover:enabled , .rec.rec-arrow:focus:enabled{
    background-color: #1D4289;
}
.rec.rec-dot_active{
    width: 24px !important;
    height: 7px;
    border-radius: 18px !important;
    background-color: #1D4289 !important;
}
.rec.rec-dot{
    width: 7px;
    height: 7px;
    background-color: #489FDF;
    box-shadow: none;
}